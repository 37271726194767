import { BsArrowDown } from "react-icons/bs";
import { useRef, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";

const Home = ({ homeData, aboutData, homeLoading, aboutLoading }) => {
  const myRef = useRef(null);

  const scrollTo = () => {
    myRef.current.scrollIntoView();
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="Home">
      {homeData ? (
        !homeLoading ? (
          <div
            className="Home-landing"
            style={{ backgroundImage: `url(${homeData[0].image})` }}
          >
            <div className="Home-landing_overlay"></div>

            <img className="Home-landing_logo" src={homeData[0].logo} alt="Anne Linn Bjølverud Fotograf Logo" />

            {/* <h1
              className="Home-landing_title"
              data-aos="fade-down"
              data-aos-delay="300"
              data-aos-duration="300"
            >
              {homeData[0].title}
            </h1>
            <h1
              className="Home-landing_undertitle"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="300"
            >
              {homeData[0].underTitle}
            </h1> */}

            <BsArrowDown onClick={scrollTo} className="Home-landing_arrowUp" />
          </div>
        ) : (
          <div className="loadWrap">
            <h1 className="loadWrap_title" style={{ color: "#000" }}>
              Laster...
            </h1>

            <div className="loadWrap_loader">
              <Loader type="TailSpin" color="#000" height={100} width={100} />
            </div>
          </div>
        )
      ) : (
        <div className="loadWrap">
          <h1
            className="loadWrap_title"
            style={{ color: "#000", textAlign: "center" }}
          >
            Laster... <br /> Laster det ikke? Last inn siden på nytt.
          </h1>
          <div className="loadWrap_loader">
              <Loader type="TailSpin" color="#000" height={100} width={100} />
            </div>
        </div>
      )}

      {aboutData ? (
        !aboutLoading ? (
          <>
            <Helmet>
              <meta charSet="UTF-8" />
              <html lang="en"></html>
              <title>Hjem | ALB Foto</title>
              <meta name="author" content="Anne-Linn Bjølverud" />
              <meta
                property="og:image"
                content={process.env.PUBLIC_URL + "/imgs/alb-logo-hvit.png"}
              />
              <meta
                name="description"
                content="Anne-Linn Bjølverud er en fotograf i Hamar området som blant annet driver med fotografering av dyr, bryllup, par, reklame, natur, barn & familie, portretter og konfirmanter. Anne-Linn driver også med digitalisering og retusj av bilder, ta kontakt for en hyggelig prat."
              />
              <meta
                property="og:description"
                content="Anne-Linn Bjølverud er en fotograf i Hamar området som blant annet driver med fotografering av dyr, bryllup, par, reklame, natur, barn & familie, portretter og konfirmanter. Anne-Linn driver også med digitalisering og retusj av bilder, ta kontakt for en hyggelig prat."
              />
              <meta property="og:url" content="https://albfoto.no/" />
              <meta property="og:type" content="website" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta property="twitter:domain" content="https://albfoto.no/" />
              <meta property="twitter:url" content="https://albfoto.no/" />
              <meta name="twitter:title" content="Hjem | ALB Foto" />
              <meta
                name="twitter:description"
                content="Anne-Linn Bjølverud er en fotograf i Hamar området som blant annet driver med fotografering av dyr, bryllup, par, reklame, natur, barn & familie, portretter og konfirmanter. Anne-Linn driver også med digitalisering og retusj av bilder, ta kontakt for en hyggelig prat."
              />

              <meta
                property="keywords"
                content="ALB Foto, Anne-Linn Bjølverud, Fotograf, Fotografering, Digitalisering, Retusj, Foto"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
            </Helmet>
            <div className="Home-about" ref={myRef}>
              <h1 className="Home-about_title">Om Meg</h1>
              <p className="Home-about_text">{aboutData[0].aboutText}</p>
              <img
                className="Home-about_img"
                src={aboutData[0].image}
                alt="Bilde av Anne-Linn Bjølverud"
              />

              <h1 className="Home-about_ref">Referanser</h1>
              <Carousel className="Home-about_carousel" enableMouseSwipe={true}>
                <div>
                  <h2 className="carouselTitle">{aboutData[0].ref1Title}</h2>
                  <h3 className="carouselText">{aboutData[0].ref1Text}</h3>
                </div>
                <div>
                  <h2 className="carouselTitle">{aboutData[0].ref2Title}</h2>
                  <h3 className="carouselText">{aboutData[0].ref2Text}</h3>
                </div>
                <div>
                  <h2 className="carouselTitle">{aboutData[0].ref3Title}</h2>
                  <h3 className="carouselText">{aboutData[0].ref3Text}</h3>
                </div>
                <div>
                  <h2 className="carouselTitle">{aboutData[0].ref4Title}</h2>
                  <h3 className="carouselText">{aboutData[0].ref4Text}</h3>
                </div>
              </Carousel>
            </div>
          </>
        ) : (
          <div className="loadWrap">
            <h1
              className="loadWrap_title"
              style={{ color: "#000", textAlign: "center" }}
            >
              Laster...
            </h1>

            <div className="loadWrap_loader">
              <Loader type="TailSpin" color="#000" height={100} width={100} />
            </div>
          </div>
        )
      ) : (
        <div className="loadWrap">
          <h1
            className="loadWrap_title"
            style={{ color: "#000", textAlign: "center" }}
          >
            Laster... <br /> Laster det ikke? Last inn siden på nytt.
          </h1>
        </div>
      )}
    </div>
  );
};

export default Home;
