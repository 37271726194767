import { useState, useEffect } from "react";
import sanityClient from "@sanity/client";
import { Route, Switch } from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./Pages/Home";
import Galleri from "./Pages/Galleri";
import Priser from "./Pages/Priser";
import Digitalisering from "./Pages/Digitalisering";
import Kontakt from "./Pages/Kontakt";
import "./SASS/base/_base.scss";
import "./SASS/main.scss";
import GalleriSide from "./Pages/GalleriSide";
import { MdArrowDropUp } from "react-icons/md";

function App() {
  const [homeData, setHomeData] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [digitaliseringData, setDigitaliseringData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [offset, setOffset] = useState(0);

  const [homeLoading, setHomeLoading] = useState(true);
  const [aboutLoading, setAboutLoading] = useState(true);
  const [galleryLoading, setGalleryLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [digitaliseringLoading, setDigitaliseringLoading] = useState(true);
  const [contactLoading, setContactLoading] = useState(true);

  const client = sanityClient({
    projectId: "2g2t2qwh",
    dataset: "production",
    apiVersion: "2021-10-18",
    useCdn: true,
  });

  useEffect(() => {
    const Home = async () => {
      await client
        .fetch(
          `*[_type == "homeContent"]{
            "logo": logo.asset->url, _id, "image": image.asset->url
      }`,
          {}
        )
        .then((res) => {
          setHomeData(res.map((item) => item));
          setTimeout(() => {
            setHomeLoading(false);
          }, 500);
        })
        .catch((err) => console.log(err));
    };

    const About = async () => {
      await client
        .fetch(
          `*[_type == "aboutContent"]{
        aboutText, _id, "image": image.asset->url, ref1Title, ref1Text, ref2Title, ref2Text, ref3Title, ref3Text, ref4Title, ref4Text
      }`,
          {}
        )
        .then((res) => {
          setAboutData(res.map((item) => item));
          setTimeout(() => {
            setAboutLoading(false);
          }, 500);
        })
        .catch((err) => console.log(err));
    };

    const Gallery = async () => {
      await client
        .fetch(
          `*[_type == "galleryContent"]{
        title, slug, imageText, _id, "image": image.asset->url
      }`,
          {}
        )
        .then((res) => {
          setGalleryData(res.map((item) => item));
          setGalleryLoading(false);
        })
        .catch((err) => console.log(err));
    };

    const Contact = async () => {
      await client
        .fetch(
          `*[_type == "contactContent"]{
              emailLink, mobileLink, facebookLink, instagramLink, mapLink, addressLink, _id,
            }`,
          {}
        )
        .then((res) => {
          setContactData(res.map((item) => item));
          setContactLoading(false);
        })
        .catch((err) => console.log(err));
    };

    Home();
    About();
    Gallery();
    Contact();

    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  console.log(homeData);

  return (
    <>
      <Nav contactData={contactData} />
      <div className="page">
        <Switch>
          <Route exact path="/">
            <Home
              homeData={homeData}
              aboutData={aboutData}
              homeLoading={homeLoading}
              aboutLoading={aboutLoading}
            />
          </Route>
          <Route exact path="/galleri">
            <Galleri
              galleryData={galleryData}
              galleryLoading={galleryLoading}
            />
          </Route>
          <Route path="/galleri/:slug">
            <GalleriSide galleryData={galleryData} />
          </Route>
          <Route exact path="/priser">
            <Priser />
          </Route>
          <Route exact path="/reproduksjon">
            <Digitalisering
              digitaliseringData={digitaliseringData}
              digitaliseringLoading={digitaliseringLoading}
            />
          </Route>
          <Route exact path="/kontakt">
            <Kontakt
              contactData={contactData}
              contactLoading={contactLoading}
            />
          </Route>
        </Switch>

        <div
          onClick={() => window.scrollTo({ top: 0 })}
          className={offset <= 120 ? "toTopBtn" : "toTopBtn active"}
        >
          <MdArrowDropUp className="toTopBtn-btn" />
        </div>
      </div>
    </>
  );
}

export default App;
