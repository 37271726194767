import { useEffect, useState } from "react";
import sanityClient from "@sanity/client";
import "../SASS/base/_Priser.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";

const Priser = () => {
  const [priceData, setPriceData] = useState([]);
  const [loadingPrice, setLoadingPrice] = useState(true);

  const client = sanityClient({
    projectId: "2g2t2qwh",
    dataset: "production",
    apiVersion: "2021-10-18",
    useCdn: true,
  });
  useEffect(() => {
    const Priser = async () => {
      await client
        .fetch(
          `*[_type == "priceContent"]{
         _id, priceText, priceTitle
      }`,
          {}
        )
        .then((res) => {
          setPriceData(res.map((item) => item));
          setLoadingPrice(false);
        })
        .catch((err) => console.log(err));
    };

    Priser();

    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="Priser">
      {priceData ? (
        !loadingPrice ? (
          <>
            <Helmet>
              <meta charSet="UTF-8" />
              <html lang="en"></html>
              <title>Priser | ALB Foto</title>
              <meta name="author" content="Anne-Linn Bjølverud" />
              <meta
                property="og:image"
                content={process.env.PUBLIC_URL + "/imgs/alb-logo-hvit.png"}
              />
              <meta
                name="description"
                content="Lurer du på noe angående pris og pakker? Ta kontakt for en hyggelig prat."
              />
              <meta
                property="og:description"
                content="Lurer du på noe angående pris og pakker? Ta kontakt for en hyggelig prat."
              />
              <meta property="og:url" content="https://albfoto.no/priser" />
              <meta property="og:type" content="website" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:domain"
                content="https://albfoto.no/priser"
              />
              <meta
                property="twitter:url"
                content="https://albfoto.no/priser"
              />
              <meta name="twitter:title" content="Priser | ALB Foto" />
              <meta
                name="twitter:description"
                content="Lurer du på noe angående pris og pakker? Ta kontakt for en hyggelig prat."
              />

              <meta
                property="keywords"
                content="ALB Foto, Anne-Linn Bjølverud, Fotograf, Fotografering, Digitalisering, Retusj, Foto"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
            </Helmet>
            <div className="Priser-container">
              <h1
                className="Priser-container_title"
                data-aos="fade-down"
                data-aos-duration="300"
              >
                Priser
              </h1>

              <div
                className="Priser-container_prices"
                data-aos="fade-down"
                data-aos-duration="300"
                data-aos-delay="300"
              >
                {priceData
                  .sort(function (a, b) {
                    return a - b;
                  })
                  .map((item) => (
                    <div
                      key={item._id}
                      className="Priser-container_prices--container"
                    >
                      <h1>{item.priceTitle}</h1>
                      {item.priceText.map((text) => (
                        <li key={text._key}>
                          {text.children.map((item) => item.text)}
                        </li>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </>
        ) : (
          <div className="loadWrap">
            <h1
              className="loadWrap_title"
              style={{ color: "#000", textAlign: "center" }}
            >
              Laster...
            </h1>

            <div className="loadWrap_loader">
              <Loader type="TailSpin" color="#000" height={100} width={100} />
            </div>
          </div>
        )
      ) : (
        <div className="loadWrap">
          <h1 className="loadWrap_title">
            Laster det ikke? <br /> Last inn siden på nytt.
          </h1>
        </div>
      )}
    </div>
  );
};

export default Priser;
