import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import "./SASS/base/_base.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { hydrate, render } from "react-dom";

const APP = (
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
)

const rootElement = document.getElementById("main");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}