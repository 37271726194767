import { Link, NavLink } from "react-router-dom";
import "../SASS/base/_Nav.scss";
import useWindowSize from "../hooks/useWindowSize";
import { MdOutlineEmail } from "react-icons/md";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { useState, useEffect } from "react";

const Nav = ({ contactData }) => {
  const [toggleValue, setToggleValue] = useState(true);
  const [socialLinks, setSocialLinks] = useState([]);
  const { width } = useWindowSize();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const scrollToTopMobile = () => {
    window.scrollTo(0, 0);
    setToggleValue(!toggleValue);
    toggleValue
      ? (window.document.body.style.overflow = "hidden")
      : (window.document.body.style.overflowY = "scroll");
  };

  const toggleNav = () => {
    setToggleValue(!toggleValue);
    toggleValue
      ? (window.document.body.style.overflow = "hidden")
      : (window.document.body.style.overflowY = "scroll");
  };

  useEffect(() => {
    const socialLinkMap = contactData.map((links) => links);

    setSocialLinks(socialLinkMap);
  }, [contactData]);

  return (
    <>
      <nav className="Nav">
        <Link to="/" className="Nav-logo" onClick={() => scrollToTop()}>
          <img
            className="Nav-logo"
            src={process.env.PUBLIC_URL + "/imgs/alb-logo-hvit.png"}
            alt="ALB-Foto logo"
          />
        </Link>

        {width > 992 ? (
          <ul className="Nav-ulLinks">
            <NavLink
              onClick={() => scrollToTop()}
              exact
              to="/"
              className="Nav-ulLinks_item"
              activeClassName="activeItem"
            >
              Hjem
            </NavLink>
            <NavLink
              onClick={() => scrollToTop()}
              exact
              to="/galleri"
              className="Nav-ulLinks_item"
              activeClassName="activeItem"
            >
              Galleri
            </NavLink>
            <NavLink
              onClick={() => scrollToTop()}
              exact
              to="/priser"
              className="Nav-ulLinks_item"
              activeClassName="activeItem"
            >
              Priser
            </NavLink>
            <NavLink
              onClick={() => scrollToTop()}
              exact
              to="/reproduksjon"
              className="Nav-ulLinks_item"
              activeClassName="activeItem"
            >
              Reproduksjon
            </NavLink>
            <NavLink
              onClick={() => scrollToTop()}
              exact
              to="/kontakt"
              className="Nav-ulLinks_item"
              activeClassName="activeItem"
            >
              Kontakt
            </NavLink>
          </ul>
        ) : (
          <>
            <div
              className={toggleValue ? "Nav-icon" : "Nav-icon active"}
              onClick={toggleNav}
            >
              <span className="Nav-line1"></span>
              <span className="Nav-line2"></span>
              <span className="Nav-line3"></span>
            </div>
          </>
        )}

        <ul className="Nav-ulSocial">
          <a
            href={`${socialLinks.map((item) => item.facebookLink)}`}
            className="Nav-ulSocial_link"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook className="Nav-ulSocial_link" />
          </a>

          <a
            href={`${socialLinks.map((link) => link.instagramLink)}`}
            className="Nav-ulSocial_link"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="Nav-ulSocial_link" />
          </a>

          <a
            href={`mailto:${socialLinks.map((link) => link.emailLink)}`}
            className="Nav-ulSocial_link"
            target="_blank"
            rel="noreferrer"
          >
            <MdOutlineEmail className="Nav-ulSocial_link" />
          </a>
        </ul>
      </nav>

      {!toggleValue ? (
        <div className={toggleValue ? "dropdown" : "dropdown toggled"}>
          <NavLink
            onClick={() => scrollToTopMobile()}
            exact
            to="/"
            className="dropdown_item"
            activeClassName="activeItem"
          >
            Hjem
          </NavLink>
          <NavLink
            onClick={() => scrollToTopMobile()}
            exact
            to="/galleri"
            className="dropdown_item"
            activeClassName="activeItem"
          >
            Galleri
          </NavLink>
          <NavLink
            onClick={() => scrollToTopMobile()}
            exact
            to="/priser"
            className="dropdown_item"
            activeClassName="activeItem"
          >
            Priser
          </NavLink>
          <NavLink
            onClick={() => scrollToTopMobile()}
            exact
            to="/reproduksjon"
            className="dropdown_item"
            activeClassName="activeItem"
          >
            Reproduksjon
          </NavLink>
          <NavLink
            onClick={() => scrollToTopMobile()}
            exact
            to="/kontakt"
            className="dropdown_item"
            activeClassName="activeItem"
          >
            Kontakt
          </NavLink>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Nav;
