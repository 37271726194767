import "../SASS/base/_GalleryPage.scss";
import { Link, useParams } from "react-router-dom";
import sanityClient from "@sanity/client";
import { useEffect, useState } from "react";
import { MdClose, MdArrowDropUp } from "react-icons/md";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTransition, animated } from "react-spring";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const GalleriSide = ({ galleryData }) => {
  const [galleryPageData, setGalleryPageData] = useState([]);
  const [galleryPageLoading, setGalleryPageLoading] = useState(true);

  const [FullImageToggled, setFullImageToggled] = useState(false);
  const [FullImageUrl, setFullImageUrl] = useState("");
  const [sliceIndex, setSliceIndex] = useState(6);
  const [imageIndex, setImageIndex] = useState(0);

  const { slug } = useParams();

  const currentGallery = galleryData.find(
    (item) => item.slug.current.toString() === slug
  );

  const currentRefs = galleryPageData.map((item) => item.imageReference._ref);

  const pageImages =
    galleryPageData
      ?.filter(
        (item) =>
          currentRefs.find((ref) => ref === currentGallery?._id) ===
          item.imageReference._ref
      )
      ?.slice(0, sliceIndex) ?? [];

  const transition = useTransition(FullImageToggled, {
    from: { y: 50, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 50, opacity: 0 },
    delay: 80,
  });

  useEffect(() => {
    const client = sanityClient({
      projectId: "2g2t2qwh",
      dataset: "production",
      apiVersion: "2021-10-18",
      useCdn: true,
    });

    const GalleryPage = async () => {
      await client
        .fetch(
          `*[_type == "galleryPageContent"]{
        imageReference, title, _id, "image": image.asset->url
      }`,
          {}
        )
        .then((res) => {
          setGalleryPageData(res.map((item) => item));
          setTimeout(() => {
            setGalleryPageLoading(false);
          }, 1000);
        })
        .catch((err) => console.log(err));
    };

    GalleryPage();
    AOS.init();
    AOS.refresh();
  }, []);

  const ToggleImage = (e, index) => {
    if (e === undefined) return;

    setFullImageUrl(e.target.src);
    setFullImageToggled(true);
    setImageIndex(index);

    window.document.body.style.overflow = "hidden";
    document.querySelector('.toTopBtn').style.display = 'none';
  }

  const CloseFullImage = () => {
    setFullImageToggled(false);
    window.document.body.style.overflowY = "scroll";
    document.querySelector('.toTopBtn').style.display = 'flex';
  }

  const nextSlide = (_imageIndex, pageImagesLength) => () => {
    if (_imageIndex !== pageImagesLength) {
      setImageIndex(_imageIndex + 1);
    } else {
      setImageIndex(0);
    }
  };

  const prevSlide = (_imageIndex, pageImagesLength) => () => {
    if (_imageIndex !== 0) {
      setImageIndex(_imageIndex - 1);
    } else if (_imageIndex === 0) {
      setImageIndex(pageImagesLength);
    }
  };

  // console.log(pageImages.length);
  // console.log(pageImages);

  return (
    <div className="GalleryPage">
      {galleryPageData ? (
        !galleryPageLoading ? (
          <>
            <Helmet>
              <meta charSet="UTF-8" />
              <html lang="en"></html>
              <title>{`${currentGallery.title} | ALB Foto`}</title>
              <meta name="author" content="Anne-Linn Bjølverud" />
              <meta property="og:image" content={currentGallery.image} />
              <meta
                name="description"
                content={`Bilder av ${currentGallery.title}, tatt av ALB Foto.`}
              />
              <meta
                property="og:description"
                content={`Bilder av ${currentGallery.title}, tatt av ALB Foto.`}
              />
              <meta property="og:url" content={`https://albfoto.no/${slug}`} />
              <meta property="og:type" content="website" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:domain"
                content={`https://albfoto.no/${slug}`}
              />
              <meta
                property="twitter:url"
                content={`https://albfoto.no/${slug}`}
              />
              <meta
                name="twitter:title"
                content={`${currentGallery.title} | ALB Foto`}
              />
              <meta
                name="twitter:description"
                content={`Bilder av ${currentGallery.title}, tatt av ALB Foto.`}
              />

              <meta
                property="keywords"
                content="ALB Foto, Anne-Linn Bjølverud, Fotograf, Fotografering, Digitalisering, Retusj, Foto"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
            </Helmet>
            <div className="GalleryPage-container">
              <Link
                to="/galleri"
                className="backArrow"
                onClick={() => window.scrollTo(0, 0)}
              >
                <MdArrowDropUp className="backArrow_arrow" />
              </Link>
              <h1 className="GalleryPage-container_title">
                {currentGallery.title}
              </h1>
              {transition((style, item) =>
                item ? (
                  <animated.div
                    style={style}
                    className={
                      FullImageToggled
                        ? "gallery-fullimg"
                        : "gallery-fullimg none"
                    }
                  >
                    <div
                      className="gallery-fullimg_overlay"
                      onClick={CloseFullImage}
                    ></div>
                    <button
                      className="sliderBtn prevBtn"
                      type="button"
                      onClick={prevSlide(imageIndex, pageImages.length - 1)}
                    >
                      <FaArrowLeft className="sliderIcon" />
                    </button>
                    <img
                      src={pageImages[imageIndex].image}
                      loading="lazy"
                      alt={currentGallery.imageText}
                    />
                    <button
                      className="sliderBtn nextBtn"
                      type="button"
                      onClick={nextSlide(imageIndex, pageImages.length - 1)}
                    >
                      <FaArrowRight className="sliderIcon" />
                    </button>
                    <MdClose onClick={CloseFullImage} className="closeArrow" />
                  </animated.div>
                ) : (
                  ""
                )
              )}
              {currentRefs.find((ref) => ref === currentGallery._id) ? (
                <div
                  className="GalleryPage-container_imgs"
                  data-aos="fade-down"
                  data-aos-duration="500"
                  data-aos-delay="500"
                >
                  {pageImages.map((item, index) => (
                    <img
                      onClick={(e) => ToggleImage(e, index)}
                      className="GalleryPage-container_imgs--img sanityImg"
                      key={`${item.imageReference._ref} + ${item.title}`}
                      src={item.image}
                      alt={item.title}
                      loading="lazy"
                      height="300px"
                      width="30%"
                    />
                  ))}
                </div>
              ) : (
                <div className="GalleryPage-container">
                  <h1 className="GalleryPage-container_title">
                    Du må legge til bilder!
                  </h1>
                </div>
              )}
              <button
                className={
                  sliceIndex <= pageImages.length
                    ? "seeMoreBtn"
                    : "seeMoreBtn none"
                }
                type="button"
                onClick={() => setSliceIndex(sliceIndex + sliceIndex)}
              >
                Se mer
              </button>
            </div>
          </>
        ) : (
          <div className="loadWrap">
            <h1 className="loadWrap_title">Laster...</h1>
            <div className="loadWrap_loader">
              <Loader type="TailSpin" color="#000" height={100} width={100} />
            </div>
          </div>
        )
      ) : (
        <div className="loadWrap">
          <h1 className="loadWrap_title">
            Laster... <br /> Laster det ikke? Last inn siden på nytt.
          </h1>
          <div className="loadWrap_loader">
            <Loader type="TailSpin" color="#000" height={100} width={100} />
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleriSide;
