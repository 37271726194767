import { MdOutlineEmail, MdOutlineLocationOn } from "react-icons/md";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "react-loader-spinner";
import { useEffect, useLayoutEffect, useRef } from "react";
import Parser from "html-react-parser";
import { Helmet } from "react-helmet";

const Kontakt = ({contactData, contactLoading}) => {
  const containerRef = useRef();

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.firstElementChild.classList.add("iframeMap");
    }
  });

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="Kontakt">
      {contactData ? (
        !contactLoading ? (
          <>
            <div className="Kontakt-container">
              <h1
                className="Kontakt-container_title"
                data-aos="fade-down"
                data-aos-duration="300"
              >
                Kontakt
              </h1>
              {contactData.map((item) => (
                <>
                  <Helmet>
                    <meta charSet="UTF-8" />
                    <html lang="en"></html>
                    <title>Kontakt | ALB Foto</title>
                    <meta name="author" content="Anne-Linn Bjølverud" />
                    <meta
                      property="og:image"
                      content={
                        process.env.PUBLIC_URL + "/imgs/alb-logo-hvit.png"
                      }
                    />
                    <meta
                      name="description"
                      content="Kontakt Anne-Linn Bjølverud for en hyggelig prat!"
                    />
                    <meta
                      property="og:description"
                      content="Kontakt Anne-Linn Bjølverud for en hyggelig prat!"
                    />
                    <meta
                      property="og:url"
                      content="https://albfoto.no/kontakt"
                    />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta
                      property="twitter:domain"
                      content="https://albfoto.no/kontakt"
                    />
                    <meta
                      property="twitter:url"
                      content="https://albfoto.no/kontakt"
                    />
                    <meta name="twitter:title" content="Kontakt | ALB Foto" />
                    <meta
                      name="twitter:description"
                      content="Kontakt Anne-Linn Bjølverud for en hyggelig prat!"
                    />

                    <meta
                      property="keywords"
                      content="ALB Foto, Anne-Linn Bjølverud, Fotograf, Fotografering, Digitalisering, Retusj, Foto"
                    />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1.0"
                    />
                  </Helmet>
                  <div
                    className="Kontakt-container_contactItems"
                    key={item._id}
                  >
                    <a
                      data-aos="fade-down"
                      data-aos-duration="300"
                      data-aos-delay="200"
                      className="Kontakt-container_contactItems--item"
                      href="https://www.google.no/search?q=Claus+Bendekes+veg+22%2C+2312+Ottestad&sxsrf=AOaemvIOg6jceEw8A-3ooTT0DgKVn4Lp0g%3A1634377988142&source=hp&ei=BKFqYb3DBeqUxc8PocGZsAU&iflsig=ALs-wAMAAAAAYWqvFJj8emCwhDG6UJkw_Vm6Rolhcfrv&ved=0ahUKEwi91aK71M7zAhVqSvEDHaFgBlYQ4dUDCAY&uact=5&oq=Claus+Bendekes+veg+22%2C+2312+Ottestad&gs_lcp=Cgdnd3Mtd2l6EAMyAggmOgcIIxDqAhAnOgcILhDqAhAnUJ0KWJ0KYLgMaAFwAHgAgAGFAYgBhQGSAQMwLjGYAQCgAQKgAQGwAQo&sclient=gws-wiz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MdOutlineLocationOn /> {item.addressLink}
                    </a>
                    <a
                      data-aos="fade-down"
                      data-aos-duration="300"
                      data-aos-delay="250"
                      className="Kontakt-container_contactItems--item"
                      href={`mailto:${item.emailLink}`}
                    >
                      <MdOutlineEmail /> {item.emailLink}
                    </a>
                    <a
                      data-aos="fade-down"
                      data-aos-duration="300"
                      data-aos-delay="300"
                      className="Kontakt-container_contactItems--item"
                      href={`tel:${item.mobileLink}}`}
                    >
                      <BsTelephone /> {item.mobileLink}
                    </a>
                    <a
                      data-aos="fade-down"
                      data-aos-duration="300"
                      data-aos-delay="350"
                      className="Kontakt-container_contactItems--item"
                      href={item.facebookLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaFacebook /> Følg min facebook side!
                    </a>
                    <a
                      data-aos="fade-down"
                      data-aos-duration="300"
                      data-aos-delay="400"
                      className="Kontakt-container_contactItems--item"
                      href={item.instagramLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaInstagram /> Følg min Instagram side!
                    </a>
                  </div>

                  <div
                    className="Kontakt-container_map"
                    data-aos="fade-down"
                    data-aos-duration="300"
                    data-aos-delay="500"
                  >
                    {Parser(item.mapLink)}
                  </div>
                </>
              ))}
            </div>
          </>
        ) : (
          <div className="loadWrap">
            <h1
              className="loadWrap_title"
              style={{ color: "#000", textAlign: "center" }}
            >
              Laster...
            </h1>

            <div className="loadWrap_loader">
              <Loader type="TailSpin" color="#000" height={100} width={100} />
            </div>
          </div>
        )
      ) : (
        <div className="loadWrap">
          <h1 className="loadWrap_title">
            Laster... <br /> Laster det ikke? Last inn siden på nytt.
          </h1>

          <div className="loadWrap_loader">
            <Loader type="TailSpin" color="#000" height={100} width={100} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Kontakt;
