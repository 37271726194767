import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import "../SASS/base/_Digitalisering.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";

const Digitalisering = () => {
  const [digitaliseringData, setDigitaliseringData] = useState([]);
  const [digitaliseringLoading, setDigitaliseringLoading] = useState(true);

  const client = sanityClient({
    projectId: "2g2t2qwh",
    dataset: "production",
    apiVersion: "2021-10-18",
    useCdn: true,
  });

  const builder = imageUrlBuilder(client);

  useEffect(() => {
    const client = sanityClient({
      projectId: "2g2t2qwh",
      dataset: "production",
      apiVersion: "2021-10-18",
      useCdn: true,
    });

    const digitalContent = async () => {
      await client
        .fetch(`*[_type == "digitalContent"]`, {})
        .then((res) => {
          setDigitaliseringData(res.map((item) => item));
          setDigitaliseringLoading(false);
        })
        .catch((err) => console.log(err));
    };

    digitalContent();

    AOS.init();
    AOS.refresh();
  }, []);

  function urlFor(source) {
    return builder.image(source);
  }

  return (
    <div className="Digitalisering">
      {digitaliseringData ? (
        <>
          {!digitaliseringLoading ? (
            <>
              <Helmet>
                <meta charSet="UTF-8" />
                <html lang="en"></html>
                <title>Reproduksjon | ALB Foto</title>
                <meta name="author" content="Anne-Linn Bjølverud" />
                <meta
                  property="og:image"
                  content={process.env.PUBLIC_URL + "/imgs/alb-logo-hvit.png"}
                />
                <meta
                  name="description"
                  content="ALB Foto tilbyr reproduksjon av fotografier både nye og eldre. Ta kontakt for pris og en hyggelig prat."
                />
                <meta
                  property="og:description"
                  content="ALB Foto tilbyr reproduksjon av fotografier både nye og eldre. Ta kontakt for pris og en hyggelig prat."
                />
                <meta
                  property="og:url"
                  content="https://albfoto.no/digitalisering"
                />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta
                  property="twitter:domain"
                  content="https://albfoto.no/digitalisering"
                />
                <meta
                  property="twitter:url"
                  content="https://albfoto.no/digitalisering"
                />
                <meta
                  name="twitter:title"
                  content="Digitalisering & Retusj | ALB Foto"
                />
                <meta
                  name="twitter:description"
                  content="ALB Foto tilbyr reproduksjon av fotografier både nye og eldre. Ta kontakt for pris og en hyggelig prat."
                />

                <meta
                  property="keywords"
                  content="ALB Foto, Anne-Linn Bjølverud, Fotograf, Fotografering, Digitalisering, Retusj, Foto"
                />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0"
                />
              </Helmet>
              <h1
                className="default-header digitaliseringTitle"
                data-aos="fade-down"
                data-aos-duration="300"
                data-aos-delay="300"
              >
                Reproduksjon
              </h1>
              {digitaliseringData.map((item) => (
                <>
                  <div
                    key={item._id}
                    className="digitalisering-retusj"
                  >
                    <div
                      className="digitalisering-retusj_left"
                      data-aos="fade-down"
                      data-aos-duration="300"
                      data-aos-delay="400"
                    >
                      <p>{item.leftText}</p>
                    </div>
                  </div>
                  <Link
                    to="/priser"
                    onClick={() => window.scrollTo(0, 0)}
                    className="digitalisering-retusj-btn"
                    data-aos="fade-down"
                    data-aos-duration="300"
                    data-aos-delay="500"
                    type="button"
                  >
                    Priser
                  </Link>
                  <div className="digitalisering-retusj-eksempler">
                    <h2
                      className="eksempelTitle"
                      data-aos="fade-down"
                      data-aos-duration="300"
                      data-aos-delay="400"
                    >
                      Eksempel
                    </h2>
                    <div className="digitalisering-retusj-eksempler-cont">
                      <div
                        data-aos="fade-down"
                        data-aos-duration="300"
                        data-aos-delay="400"
                        className="digitalisering-retusj-eksempler-cont_digi"
                      >
                        <div>
                          <img
                            src={urlFor(item.image1.asset._ref).url()}
                            alt="Digitalisering bilde FØR"
                          />
                          <h3>FØR</h3>
                        </div>
                        <div>
                          <img
                            src={urlFor(item.image2.asset._ref).url()}
                            alt="Digitalisering bilde ETTER"
                          />
                          <h3>ETTER</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
          <div className="loadWrap">
            <h1
              className="loadWrap_title"
              style={{ color: "#000", textAlign: "center" }}
            >
              Laster...
            </h1>

            <div className="loadWrap_loader">
              <Loader type="TailSpin" color="#000" height={100} width={100} />
            </div>
          </div>
          )}
        </>
      ) : (
        <div className="loadWrap">
            <h1
              className="loadWrap_title"
              style={{ color: "#000", textAlign: "center" }}
            >
            Laster... <br /> Laster det ikke? Last inn siden på nytt.
            </h1>

            <div className="loadWrap_loader">
              <Loader type="TailSpin" color="#000" height={100} width={100} />
            </div>
          </div>
      )}
    </div>
  );
};

export default Digitalisering;
