import { useEffect } from "react";
import "../SASS/base/_Gallery.scss";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "react-loader-spinner";

const Galleri = ({ galleryData, galleryLoading }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className="galleri">
      {galleryData ? (
        !galleryLoading ? (
          <>
            <Helmet>
              <meta charSet="UTF-8" />
              <html lang="en"></html>
              <title>Galleri | ALB Foto</title>
              <meta name="author" content="Anne-Linn Bjølverud" />
              <meta
                property="og:image"
                content={process.env.PUBLIC_URL + "/imgs/alb-logo-hvit.png"}
              />
              <meta
                name="description"
                content="Galleri kategorier av fotograferinger fra ALB Foto."
              />
              <meta
                property="og:description"
                content="Galleri kategorier av fotograferinger fra ALB Foto."
              />
              <meta property="og:url" content="https://albfoto.no/galleri" />
              <meta property="og:type" content="website" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:domain"
                content="https://albfoto.no/galleri"
              />
              <meta
                property="twitter:url"
                content="https://albfoto.no/galleri"
              />
              <meta name="twitter:title" content="Galleri | ALB Foto" />
              <meta
                name="twitter:description"
                content="Galleri kategorier av fotograferinger fra ALB Foto."
              />

              <meta
                property="keywords"
                content="ALB Foto, Anne-Linn Bjølverud, Fotograf, Fotografering, Digitalisering, Retusj, Foto"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
            </Helmet>
            <div className="galleri-container">
              <h1
                data-aos="fade-down"
                data-aos-delay="300"
                data-aos-duration="300"
              >
                Galleri
              </h1>

              {galleryData.map((item) => (
                <Link
                  onClick={() => scrollToTop()}
                  key={item._id}
                  to={`/galleri/${item.slug.current}`}
                  className="grid-item"
                >
                  <h2 className="grid-item_title">{item.title}</h2>
                  <img
                    src={item.image}
                    alt={item.imageText}
                    loading="lazy"
                    height="100%"
                    width="100%"
                  />
                </Link>
              ))}
            </div>
          </>
        ) : (
          <div className="loadWrap">
            <h1
              className="loadWrap_title"
              style={{ color: "#000", textAlign: "center" }}
            >
              Laster...
            </h1>

            <div className="loadWrap_loader">
              <Loader type="TailSpin" color="#000" height={100} width={100} />
            </div>
          </div>
        )
      ) : (
        <div className="loadWrap">
          <h1 className="loadWrap_title">
            Laster det ikke? <br /> Last inn siden på nytt.
          </h1>
        </div>
      )}
    </section>
  );
};

export default Galleri;
